
import { get, getWithHeader, postWithHeaders } from "@/api/axios";
import { Component, Vue } from "vue-property-decorator";
import { IExaQAItem, IExaSku } from "@/state/models";
import EntityPropertiesEdit from "@/components/EntityPropertiesEdit.vue";
import TableRootEntities from "@/components/TableRootEntities.vue";


interface SessionEntity {
    id: string;
    total: number;
    isCompleted: boolean;
}

@Component({
    components: {
        EntityPropertiesEdit,
        TableRootEntities,
    }
})
export default class ExaQuestionView extends Vue {

    public entity: IExaSku | null = null;
    public currentSessionId: string | null = null;
    public question: IExaQAItem | null = null;
    public totalQuestions: number = -1;

    public selectedAnswers: ({text: string, index: number}[]) | {text: string, index: number} | null = null;


    public session!: SessionEntity;

    created() {
        const id = this.$route.params.id;
        
        get(`/api/@examination/exa/runtime/testings/${id}/start`).then(async x => {
            this.session = x.data;
            if (this.session.isCompleted) {
                this.$router.push({ name: "exa_test_result", params: { id: id } });
                return;
            }

            this.currentSessionId = x.data.id;
            this.totalQuestions = x.data.total;
            console.log("Session started", this.session);

            await getWithHeader(`/api/@examination/exa/runtime/session/next`, {
                    'X-Session-ID': this.currentSessionId
                }).then(x => {
                    this.question = x.data;
                    console.log("Success fetch question", x.data);
                });
        });

        /*
         get(`/api/@examination/exa/sku/${id}`).then(x => {
            this.entity = x.data;
            console.log("Success fetch sku", this.entity, id);
            get(`/api/@examination/exa/runtime/task/${id}`).then(x => {
                this.currentSessionId = x.data.id;
                this.totalQuestions = x.data.total;

                getWithHeader(`/api/@examination/exa/runtime/session/next`, {
                    'X-Session-ID': this.currentSessionId
                }).then(x => {
                    this.question = x.data;
                })
            })
        });*/
        
    }

    get indexOfSelectedAnswers() {
        if (!this.question) return [];
        if (!this.selectedAnswers) return [];

        if (this.question.allowedMultipleAnswer && Array.isArray(this.selectedAnswers)) {
            return this.selectedAnswers.map(y => y.index);
        } else if ('index' in this.selectedAnswers) {
            return [this.selectedAnswers.index]
        }
        return [];
    }

    get isLastAnswer() {
        if (!this.question) return false;
        return this.question.index + 1 == this.totalQuestions;
    }


    nextOrComplete() {
        if (!this.question) return;


        console.log(`selectedAnswers`, this.indexOfSelectedAnswers);
        console.log(`totalQuestions`, this.totalQuestions);

        postWithHeaders(`/api/@examination/exa/runtime/session/answer`, {index: this.question.index, selected: this.indexOfSelectedAnswers}, {
                    'X-Session-ID': this.currentSessionId
                }).then(z => {
                    console.log("Success send answer", z.data);
                    let isLast = this.isLastAnswer;
                    this.selectedAnswers = null;

                    getWithHeader(`/api/@examination/exa/runtime/session/next`, {
                        'X-Session-ID': this.currentSessionId
                        }).then(x => {
                            if (isLast) {
                                this.$router.push({ name: "exa_test_result", params: { id: this.$route.params.id } });
                                return;
                            }

                            this.question = x.data;
                            console.log(`next`, x.data);
                        });
                });
    }

}
